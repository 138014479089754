<template>
  <v-footer padless fixed color="#68B7DF">
    <v-row class="pt-3 pb-3">
      <v-col class="col-8 offset-2 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3">
        <v-row>
          <v-col
          class="text-center text-sm-left footer-text pt-1 pb-1 pt-sm-3 pb-sm-3 col-sm-5 col-md-4"
          cols="12">
            Created by <a href="https://www.osintanalytics.com/" target="_blank">Osint Analytics</a>
          </v-col>
          <v-col class="col-12 col-sm-3 col-md-4">
            <v-row>
               <v-col
                    class="text-center text-sm-left footer-text pt-1 pb-1 pt-sm-3 pb-sm-3"
                    cols="4">
                  <a style="text-decoration:none;" href="https://www.linkedin.com/company/leita/?viewAsMember=true" target="_blank">
                    <v-icon
                        dense
                        color="white"
                    >
                      mdi-linkedin
                    </v-icon>
                  </a>
                  <!--        <a href="mailto:support@leita.ai">LinkedIn</a>-->
                </v-col>

                <v-col
                    class="text-center footer-text pt-1 pb-1 pt-sm-3 pb-sm-3"
                    cols="4">
                  <a style="text-decoration:none;" href="https://twitter.com/osintanalytics" target="_blank">
                    <v-icon
                        dense
                        color="white"
                    >
                      mdi-twitter
                    </v-icon>
                  </a>
                  <!--        <a href="mailto:support@leita.ai">Twitter</a>-->
                </v-col>


                <v-col
                    class="text-center text-sm-right footer-text pt-1 pb-1 pt-sm-3 pb-sm-3"
                    cols="4">
                  <a style="text-decoration:none;" href="mailto:support@leita.ai">
                    <v-icon
                        dense
                        color="white"
                    >
                      mdi-email
                    </v-icon>
                  </a>
                  <!--          <a href="mailto:support@leita.ai">support@leita.ai</a>-->
                </v-col>
            </v-row>  
          </v-col> 
          <v-col
              class="text-center text-sm-right footer-text pt-1 pb-1 pt-sm-3 pb-sm-3 col-sm-4"
              cols="12">
            <v-icon
                dense
                color="white"
            >
              mdi-phone
            </v-icon>
            <a style="text-decoration:none;" href="tel:+47 62 52 17 00">+47 62 52 17 00</a>
          </v-col>
        </v-row>
      </v-col>
      

    </v-row>
    <cookie-law theme="dark-lime">
      <div slot="message">
        By clicking «Accept» you accept the developer of Leita, OSINT Analytics’ <a target="_blank"
                                                                                    href="/Terms and Conditions OSINT.pdf">terms
        and conditions</a> and <a target="_blank" href="/Leita Privacy Policy EN.pdf">privacy policy</a>
      </div>
    </cookie-law>
  </v-footer>
</template>


<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'Footer',
  components: {CookieLaw}


}
</script>
<style>
.text-center {
  text-align: center !important;
}
</style>